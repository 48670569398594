import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import getRpcOptions from '../api/getRpcOptions';

import {RpcOptions} from '@protobuf-ts/runtime-rpc';
import {
  GetAllVaccinationsRequest,
  GetAllVaccinationsResponse,
} from '@verily-src/verily1-protos/phr/vaccinations/bff/api/v1/vaccinations_bff';
import {VaccinationsBFFServiceClient} from '@verily-src/verily1-protos/phr/vaccinations/bff/api/v1/vaccinations_bff.client';
import {LogGrpcUnaryInterceptor} from '@verily-src/verily1-verily-me-web-common-typescript/src/utilities/LogGrpcUnaryInterceptor';
import {createSessionCache} from './CachedEndpoint';

let host = '/api';
let isLocal = false;
let rpcOptions: RpcOptions = {};

if (process.env.NODE_ENV === 'development') {
  const TARGET_URL = 'localhost';
  const TARGET_PORT = '8997';
  host = `http://${TARGET_URL}:${TARGET_PORT}`;
  isLocal = true;
}

class VaccinationsService {
  private vaccinationsServiceClient?: VaccinationsBFFServiceClient;
  private allVaccinationsCache = createSessionCache<GetAllVaccinationsResponse>(
    () => this._getAllVaccinations()
  );

  async getClient() {
    if (!isLocal) {
      rpcOptions = await getRpcOptions(); // set the rpcOptions a single time at client level
    }
    if (!this.vaccinationsServiceClient) {
      const transport = new GrpcWebFetchTransport({
        baseUrl: host,
        interceptors: [new LogGrpcUnaryInterceptor()],
      });

      this.vaccinationsServiceClient = new VaccinationsBFFServiceClient(
        transport
      );
    }

    return this.vaccinationsServiceClient;
  }

  getAllVaccinations = this.allVaccinationsCache.get;

  private async _getAllVaccinations(): Promise<GetAllVaccinationsResponse> {
    const request: GetAllVaccinationsRequest = {
      pageSize: 0,
      pageToken: '',
    };

    const vaccinationsServiceClient = await this.getClient();

    return vaccinationsServiceClient.getAllVaccinations(request, rpcOptions)
      .response;
  }
}

export default new VaccinationsService();
