/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "phr/backend/api/v1/phr_service.proto" (package "phr.backend.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Patient as Patient$ } from "../../../../proto/google/fhir/proto/r4/core/resources/patient";
import { Practitioner as Practitioner$ } from "../../../../proto/google/fhir/proto/r4/core/resources/practitioner";
import { Procedure as Procedure$ } from "../../../../proto/google/fhir/proto/r4/core/resources/procedure";
import { Observation as Observation$ } from "../../../../proto/google/fhir/proto/r4/core/resources/observation";
import { Condition as Condition$ } from "../../../../proto/google/fhir/proto/r4/core/resources/condition";
import { DocumentReference as DocumentReference$ } from "../../../../proto/google/fhir/proto/r4/core/resources/document_reference";
import { Immunization as Immunization$ } from "../../../../proto/google/fhir/proto/r4/core/resources/immunization";
import { Encounter as Encounter$ } from "../../../../proto/google/fhir/proto/r4/core/resources/encounter";
/**
 * Represents a wrapper for an Encounter FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Encounter
 */
export interface Encounter {
    /**
     * AIP resource name for the Encounter
     *
     * Format: "encounter/{encounter_id}" is equivalent to the FHIR
     * Encounter path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The Encounter from FHIR.
     *
     * @generated from protobuf field: google.fhir.r4.core.Encounter fhir_encounter = 2;
     */
    fhirEncounter?: Encounter$;
}
/**
 * Represents a wrapper for a Immunization FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Immunization
 */
export interface Immunization {
    /**
     * AIP resource name for the Immunization
     *
     * Format: “immunization/{immunization_id}” is equivalent to the FHIR
     * Immunization path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The Immunization from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Immunization fhir_immunization = 2;
     */
    fhirImmunization?: Immunization$;
}
/**
 * Represents a wrapper for an DocumentReference FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.DocumentReference
 */
export interface DocumentReference {
    /**
     * AIP resource name for the DocumentReference
     *
     * Format: "documentReference/{documentReference_id}" is equivalent to the FHIR
     * DocumentReferencer path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The DocumentReference from FHIR.
     *
     * @generated from protobuf field: google.fhir.r4.core.DocumentReference fhir_document_reference = 2;
     */
    fhirDocumentReference?: DocumentReference$;
}
/**
 * The specific type of FHIR resource to query for
 * Deprecated: Use HealthRecordResourceType instead.
 *
 * @generated from protobuf message phr.backend.api.v1.FhirResource
 */
export interface FhirResource {
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "immunization";
        /**
         * The FHIR Immunization resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Immunization immunization = 1;
         */
        immunization: Immunization;
    } | {
        oneofKind: "encounter";
        /**
         * The FHIR Encounter resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Encounter encounter = 2;
         */
        encounter: Encounter;
    } | {
        oneofKind: "procedure";
        /**
         * The FHIR Procedure resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Procedure procedure = 3;
         */
        procedure: Procedure; // Add new FHIR resources here for future subsystems
    } | {
        oneofKind: undefined;
    };
}
/**
 * Represents all of the relevant metadata of a Immunization FHIR resource
 * to be displayed in the Vaccinations MFE
 *
 * @generated from protobuf message phr.backend.api.v1.Vaccination
 */
export interface Vaccination {
    /**
     * AIP resource name for the Immunization
     *
     * Format: "immunization/{immunization_id}" is equivalent to the FHIR
     * Immunization path with resource id.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the vaccine (CDC group name provided by enrichments)
     * CDC name map to VerilyMe name
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The date time in ISO 8601 based on administration
     * date (if provided by Zus)
     *
     * @generated from protobuf field: string date = 3;
     */
    date: string;
    /**
     * The location the vaccine administration (e.g. USSF Marina)
     *
     * @generated from protobuf field: string location = 4;
     */
    location: string;
}
/**
 * The specific type of PHR resource that maps from their
 * respective FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.HealthRecordResource
 */
export interface HealthRecordResource {
    /**
     * @generated from protobuf oneof: type
     */
    type: {
        oneofKind: "vaccination";
        /**
         * Equivalent to the FHIR Immunization resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Vaccination vaccination = 1;
         */
        vaccination: Vaccination;
    } | {
        oneofKind: "pastEvent";
        /**
         * Equivalent to the FHIR Encounter resource
         *
         * @generated from protobuf field: phr.backend.api.v1.PastEvent past_event = 2;
         */
        pastEvent: PastEvent;
    } | {
        oneofKind: "condition";
        /**
         * Equivalent to the FHIR Condition resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Condition condition = 3;
         */
        condition: Condition;
    } | {
        oneofKind: "observation";
        /**
         * Equivalent to the FHIR Observation resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Observation observation = 4;
         */
        observation: Observation;
    } | {
        oneofKind: "procedure";
        /**
         * Equivalent to the FHIR Procedure resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Procedure procedure = 5;
         */
        procedure: Procedure;
    } | {
        oneofKind: "practitioner";
        /**
         * Equivalent to the FHIR Practitioner resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Practitioner practitioner = 6;
         */
        practitioner: Practitioner;
    } | {
        oneofKind: "patient";
        /**
         * Equivalent to the FHIR Patient resource
         *
         * @generated from protobuf field: phr.backend.api.v1.Patient patient = 7;
         */
        patient: Patient;
    } | {
        oneofKind: "encounter";
        /**
         * Equivalent to the FHIR Encounter resource
         *
         * @generated from protobuf field: phr.backend.api.v1.RawEncounter encounter = 8;
         */
        encounter: RawEncounter;
    } | {
        oneofKind: "immunization";
        /**
         * Equivalent to the FHIR Immunization resource
         *
         * @generated from protobuf field: phr.backend.api.v1.RawImmunization immunization = 9;
         */
        immunization: RawImmunization;
    } | {
        oneofKind: "pastEventDetail";
        /**
         * Equivalent to the FHIR DocumentReference resource
         *
         * @generated from protobuf field: phr.backend.api.v1.PastEventDetail past_event_detail = 10;
         */
        pastEventDetail: PastEventDetail;
    } | {
        oneofKind: "procedureDetail";
        /**
         * @generated from protobuf field: phr.backend.api.v1.ProcedureDetail procedure_detail = 11;
         */
        procedureDetail: ProcedureDetail; // Add new PHR resources here for future subsystems
    } | {
        oneofKind: undefined;
    };
}
/**
 * Represents a wrapper for a Condition FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Condition
 */
export interface Condition {
    /**
     * The Condition from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Condition fhir_condition = 1;
     */
    fhirCondition?: Condition$;
}
/**
 * Represents a wrapper for a Observation FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Observation
 */
export interface Observation {
    /**
     * The Observation from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Observation fhir_observation = 1;
     */
    fhirObservation?: Observation$;
}
/**
 * Represents a wrapper for a Procedure FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Procedure
 */
export interface Procedure {
    /**
     * The Procedure from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Procedure fhir_procedure = 1;
     */
    fhirProcedure?: Procedure$;
    /**
     * AIP resource name for the Procedure
     *
     * Format: "procedure/{procedure_id}" is equivalent to the FHIR
     * Procedure path with resource id.
     *
     * @generated from protobuf field: string id = 2;
     */
    id: string;
    /**
     * The name of the procedure (CDC group name provided by enrichments)
     * CDC name map to VerilyMe name
     *
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * The date time in ISO 8601 based on administration
     * date (if provided by Zus)
     *
     * @generated from protobuf field: string date = 4;
     */
    date: string;
    /**
     * The location the procedure (e.g. USSF Marina)
     *
     * @generated from protobuf field: string location = 5;
     */
    location: string;
}
/**
 * @generated from protobuf message phr.backend.api.v1.ProcedureDetail
 */
export interface ProcedureDetail {
    /**
     * AIP resource name for the Procedure
     *
     * Format: "procedure/{procedure_id}" is equivalent to the FHIR
     * Immunization path with resource id.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The name of the procedure
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * The date time in ISO 8601 based on administration
     * date (if provided by Zus)
     *
     * @generated from protobuf field: string date = 3;
     */
    date: string;
    /**
     * The location the procedure administration (e.g. USSF Marina)
     *
     * @generated from protobuf field: string location = 4;
     */
    location: string;
}
/**
 * Represents a wrapper for a Practitioner FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Practitioner
 */
export interface Practitioner {
    /**
     * The Practitioner from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Practitioner fhir_practitioner = 1;
     */
    fhirPractitioner?: Practitioner$;
}
/**
 * Represents a wrapper for a Patient FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.Patient
 */
export interface Patient {
    /**
     * The Patient from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Patient fhir_patient = 1;
     */
    fhirPatient?: Patient$;
}
/**
 * Represents a wrapper for an Encounter FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.RawEncounter
 */
export interface RawEncounter {
    /**
     * The Encounter from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Encounter fhir_encounter = 1;
     */
    fhirEncounter?: Encounter$;
}
/**
 * Represents a wrapper for an Immunization FHIR resource
 *
 * @generated from protobuf message phr.backend.api.v1.RawImmunization
 */
export interface RawImmunization {
    /**
     * The Immunization from FHIR
     *
     * @generated from protobuf field: google.fhir.r4.core.Immunization fhir_immunization = 1;
     */
    fhirImmunization?: Immunization$;
}
/**
 * Represents all the relevant metadata of an Encounter FHIR Resource to be
 * displayed in both the Past Events Landing page and the Past Events Section
 *
 * @generated from protobuf message phr.backend.api.v1.PastEvent
 */
export interface PastEvent {
    /**
     * AIP resource name for the Encounter
     *
     * Format: "encounter/{encounter_id}" is equivalent to the FHIR
     * Encounter path with resource id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The start time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string start_time = 2;
     */
    startTime: string;
    /**
     * The end time of the encounter in ISO 8601
     * (-- api-linter: core::0142::time-field-type=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string end_time = 11;
     */
    endTime: string;
    /**
     * The name of the Practitioner from Zus FHIR
     * (-- api-linter: core::0122::name-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: string practitioner_name = 3;
     */
    practitionerName: string;
    /**
     * The specialty of the Practitioner from Zus FHIR
     *
     * @generated from protobuf field: string practitioner_specialty = 4;
     */
    practitionerSpecialty: string;
    /**
     * The encounter type: (TBD - https://www.hl7.org/fhir/valueset-encounter-type.html)
     *
     * @generated from protobuf field: string type = 5;
     */
    type: string;
    /**
     * The encounter classification: (TBD - https://terminology.hl7.org/5.1.0/ValueSet-encounter-class.html)
     *
     * @generated from protobuf field: string classification = 6;
     */
    classification: string;
    /**
     * The organization where encounter took place (TBD - Encounter.location.managingOrganization.name)
     *
     * @generated from protobuf field: string organization = 7;
     */
    organization: string;
    /**
     * The location of the organization where encounter took place (TBD - Encounter.location.display)
     *
     * @generated from protobuf field: string location = 8;
     */
    location: string;
    /**
     * The reason for the encounter
     *
     * @generated from protobuf field: string reason = 9;
     */
    reason: string;
    /**
     * Provider notes from the visit (TBD on if this is needed)
     *
     * @generated from protobuf field: string visit_notes = 10;
     */
    visitNotes: string;
    /**
     * Title to display for the encounter
     *
     * @generated from protobuf field: string title = 12;
     */
    title: string;
}
/**
 * Represents all of the relevant metadata of a DocumentReference FHIR resource
 * to be displayed in the PastEvents Tertiary and Quaternary page.
 *
 * @generated from protobuf message phr.backend.api.v1.PastEventDetail
 */
export interface PastEventDetail {
    /**
     * AIP resource name for the DocumentReference
     *
     * Format: "documentReference/{documentReference_id}" is equivalent to the FHIR
     * DocumentReference path with resource id.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The title of the document
     *
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * The body of the document in HTML format (TBD - xml, pdf, tiff, jpeg, plain, png, cvs)
     *
     * @generated from protobuf field: string body = 3;
     */
    body: string;
}
/**
 * Get all of a specific resource by type for a participant/patient
 *
 * @generated from protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeRequest
 */
export interface GetHealthRecordResourcesByTypeRequest {
    /**
     * The list of patient IDs.
     *
     * @generated from protobuf field: repeated string patient_ids = 1;
     */
    patientIds: string[];
    /**
     * The maximum number of resources to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 resources will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * A page token, received from a previous
     * `GetHealthRecordResourcesByTypeRequest` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided
     * to `GetHealthRecordResourcesByTypeRequest`
     * must match the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
    /**
     * The type of FHIR Resource to query for
     * Deprecated: Use record_type instead.
     *
     * @deprecated
     * @generated from protobuf field: phr.backend.api.v1.FhirResource resource = 4 [deprecated = true];
     */
    resource?: FhirResource;
    /**
     * The type of FHIR Resource to query for
     *
     * @generated from protobuf field: phr.backend.api.v1.HealthRecordResourceType record_type = 5;
     */
    recordType: HealthRecordResourceType;
}
/**
 * Get response message for return PHR resources
 *
 * @generated from protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeResponse
 */
export interface GetHealthRecordResourcesByTypeResponse {
    /**
     * List of PHR resources to return
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.HealthRecordResource resources = 1;
     */
    resources: HealthRecordResource[];
}
/**
 * TODO(ONEVERILY-48760) Deprecate this proto
 * Request to get the N most recent encounters for a list of patients.
 *
 * @generated from protobuf message phr.backend.api.v1.GetRecentEncountersForListOfPatientsRequest
 */
export interface GetRecentEncountersForListOfPatientsRequest {
    /**
     * The list of patient IDs.
     *
     * @generated from protobuf field: repeated string patient_ids = 1;
     */
    patientIds: string[];
    /**
     * Number of most recent encounters to get for patient
     * (-- api-linter: core::0141::count-suffix=disabled
     *     aip.dev/not-precedent: This linter check is adding PR noise and the component is working fine despite this check failing. --)
     *
     * @generated from protobuf field: int32 num_encounters = 2;
     */
    numEncounters: number;
}
/**
 * TODO(ONEVERILY-48760) Deprecate this proto
 * Encounters type.
 *
 * @generated from protobuf message phr.backend.api.v1.Encounters
 */
export interface Encounters {
    /**
     * The list of encounters.
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Encounter encounters = 1;
     */
    encounters: Encounter[];
}
/**
 * TODO(ONEVERILY-48760) Deprecate this proto
 * Request to get all encounters for a list of patients.
 *
 * @generated from protobuf message phr.backend.api.v1.GetAllEncountersForListOfPatientsRequest
 */
export interface GetAllEncountersForListOfPatientsRequest {
    /**
     * The list of patient IDs.
     *
     * @generated from protobuf field: repeated string patient_ids = 1;
     */
    patientIds: string[];
    /**
     * The maximum number of encounters to return. The service may return fewer
     * than this value.
     * If unspecified, at most 50 encounters will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `GetAllEncountersForPatientRequest` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `GetAllEncountersForPatientRequest`
     * must match the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
}
/**
 * The request to get a single FHIR resource by their ID
 *
 * @generated from protobuf message phr.backend.api.v1.GetHealthRecordResourceByIDRequest
 */
export interface GetHealthRecordResourceByIDRequest {
    /**
     * The ID of the FHIR resource
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The type of FHIR Resource to query for
     *
     * @generated from protobuf field: phr.backend.api.v1.HealthRecordResourceType record_type = 2;
     */
    recordType: HealthRecordResourceType;
}
/**
 * The response to get a single FHIR resource by their ID and translated to
 * their HealthRecordResource counterpart
 *
 * @generated from protobuf message phr.backend.api.v1.GetHealthRecordResourceByIDResponse
 */
export interface GetHealthRecordResourceByIDResponse {
    /**
     * @generated from protobuf field: phr.backend.api.v1.HealthRecordResource resource = 1;
     */
    resource?: HealthRecordResource;
}
/**
 * The request to get all PastEventDetails from a FHIR Encounter ID
 *
 * @generated from protobuf message phr.backend.api.v1.GetPastEventDetailsFromEncounterRequest
 */
export interface GetPastEventDetailsFromEncounterRequest {
    /**
     * @generated from protobuf field: string encounter_id = 1;
     */
    encounterId: string;
}
/**
 * The response to get all PastEventDetails from a FHIR Encounter ID
 *
 * @generated from protobuf message phr.backend.api.v1.GetPastEventDetailsFromEncounterResponse
 */
export interface GetPastEventDetailsFromEncounterResponse {
    /**
     * @generated from protobuf field: repeated phr.backend.api.v1.PastEventDetail past_event_details = 1;
     */
    pastEventDetails: PastEventDetail[];
}
/**
 * @generated from protobuf enum phr.backend.api.v1.HealthRecordResourceType
 */
export enum HealthRecordResourceType {
    /**
     * @generated from protobuf enum value: HEALTH_RECORD_RESOURCE_TYPE_UNSPECIFIED = 0;
     */
    HEALTH_RECORD_RESOURCE_TYPE_UNSPECIFIED = 0,
    /**
     * Cleaned version of the FHIR Encounter resource
     *
     * @generated from protobuf enum value: VACCINATION = 1;
     */
    VACCINATION = 1,
    /**
     * Cleaned version of the FHIR Immunization resource
     *
     * @generated from protobuf enum value: PAST_EVENT = 2;
     */
    PAST_EVENT = 2,
    /**
     * Equivalent to the FHIR Condition resource
     *
     * @generated from protobuf enum value: CONDITION = 3;
     */
    CONDITION = 3,
    /**
     * Equivalent to the FHIR Observation resource
     *
     * @generated from protobuf enum value: OBSERVATION = 4;
     */
    OBSERVATION = 4,
    /**
     * Equivalent to the FHIR Procedure resource
     *
     * @generated from protobuf enum value: PROCEDURE = 5;
     */
    PROCEDURE = 5,
    /**
     * Equivalent to the FHIR practitioner resource
     *
     * @generated from protobuf enum value: PRACTITIONER = 6;
     */
    PRACTITIONER = 6,
    /**
     * Equivalent to the FHIR patient resource
     *
     * @generated from protobuf enum value: PATIENT = 7;
     */
    PATIENT = 7,
    /**
     * Equivalent to the FHIR Encounter resource
     *
     * @generated from protobuf enum value: ENCOUNTER = 8;
     */
    ENCOUNTER = 8,
    /**
     * Equivalent to the FHIR Immunization resource
     *
     * @generated from protobuf enum value: IMMUNIZATION = 9;
     */
    IMMUNIZATION = 9,
    /**
     * Equivalent to the FHIR DocumentReference resource
     *
     * @generated from protobuf enum value: DOCUMENT_REFERENCE = 10;
     */
    DOCUMENT_REFERENCE = 10
}
// @generated message type with reflection information, may provide speed optimized methods
class Encounter$Type extends MessageType<Encounter> {
    constructor() {
        super("phr.backend.api.v1.Encounter", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "fhir_encounter", kind: "message", T: () => Encounter$ }
        ], { "google.api.resource": { type: "verily.health/Encounter", pattern: ["encounter/{encounter_id}"], plural: "encounters", singular: "encounter" } });
    }
    create(value?: PartialMessage<Encounter>): Encounter {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Encounter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Encounter): Encounter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.fhir.r4.core.Encounter fhir_encounter */ 2:
                    message.fhirEncounter = Encounter$.internalBinaryRead(reader, reader.uint32(), options, message.fhirEncounter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Encounter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.fhir.r4.core.Encounter fhir_encounter = 2; */
        if (message.fhirEncounter)
            Encounter$.internalBinaryWrite(message.fhirEncounter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Encounter
 */
export const Encounter = new Encounter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Immunization$Type extends MessageType<Immunization> {
    constructor() {
        super("phr.backend.api.v1.Immunization", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "fhir_immunization", kind: "message", T: () => Immunization$ }
        ], { "google.api.resource": { type: "verily.health/Immunization", pattern: ["immunization/{immunization_id}"], plural: "immunizations", singular: "immunization" } });
    }
    create(value?: PartialMessage<Immunization>): Immunization {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Immunization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Immunization): Immunization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.fhir.r4.core.Immunization fhir_immunization */ 2:
                    message.fhirImmunization = Immunization$.internalBinaryRead(reader, reader.uint32(), options, message.fhirImmunization);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Immunization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.fhir.r4.core.Immunization fhir_immunization = 2; */
        if (message.fhirImmunization)
            Immunization$.internalBinaryWrite(message.fhirImmunization, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Immunization
 */
export const Immunization = new Immunization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DocumentReference$Type extends MessageType<DocumentReference> {
    constructor() {
        super("phr.backend.api.v1.DocumentReference", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "fhir_document_reference", kind: "message", T: () => DocumentReference$ }
        ], { "google.api.resource": { type: "verily.health/DocumentReference", pattern: ["documentReference/{documentReference_id}"], plural: "documentReferences", singular: "documentReference" } });
    }
    create(value?: PartialMessage<DocumentReference>): DocumentReference {
        const message = { name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DocumentReference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DocumentReference): DocumentReference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* google.fhir.r4.core.DocumentReference fhir_document_reference */ 2:
                    message.fhirDocumentReference = DocumentReference$.internalBinaryRead(reader, reader.uint32(), options, message.fhirDocumentReference);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DocumentReference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* google.fhir.r4.core.DocumentReference fhir_document_reference = 2; */
        if (message.fhirDocumentReference)
            DocumentReference$.internalBinaryWrite(message.fhirDocumentReference, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.DocumentReference
 */
export const DocumentReference = new DocumentReference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FhirResource$Type extends MessageType<FhirResource> {
    constructor() {
        super("phr.backend.api.v1.FhirResource", [
            { no: 1, name: "immunization", kind: "message", oneof: "type", T: () => Immunization },
            { no: 2, name: "encounter", kind: "message", oneof: "type", T: () => Encounter },
            { no: 3, name: "procedure", kind: "message", oneof: "type", T: () => Procedure }
        ]);
    }
    create(value?: PartialMessage<FhirResource>): FhirResource {
        const message = { type: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FhirResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FhirResource): FhirResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* phr.backend.api.v1.Immunization immunization */ 1:
                    message.type = {
                        oneofKind: "immunization",
                        immunization: Immunization.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).immunization)
                    };
                    break;
                case /* phr.backend.api.v1.Encounter encounter */ 2:
                    message.type = {
                        oneofKind: "encounter",
                        encounter: Encounter.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).encounter)
                    };
                    break;
                case /* phr.backend.api.v1.Procedure procedure */ 3:
                    message.type = {
                        oneofKind: "procedure",
                        procedure: Procedure.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).procedure)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FhirResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* phr.backend.api.v1.Immunization immunization = 1; */
        if (message.type.oneofKind === "immunization")
            Immunization.internalBinaryWrite(message.type.immunization, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Encounter encounter = 2; */
        if (message.type.oneofKind === "encounter")
            Encounter.internalBinaryWrite(message.type.encounter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Procedure procedure = 3; */
        if (message.type.oneofKind === "procedure")
            Procedure.internalBinaryWrite(message.type.procedure, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.FhirResource
 */
export const FhirResource = new FhirResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Vaccination$Type extends MessageType<Vaccination> {
    constructor() {
        super("phr.backend.api.v1.Vaccination", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Vaccination>): Vaccination {
        const message = { id: "", name: "", date: "", location: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Vaccination>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Vaccination): Vaccination {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string date */ 3:
                    message.date = reader.string();
                    break;
                case /* string location */ 4:
                    message.location = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Vaccination, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string date = 3; */
        if (message.date !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.date);
        /* string location = 4; */
        if (message.location !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.location);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Vaccination
 */
export const Vaccination = new Vaccination$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HealthRecordResource$Type extends MessageType<HealthRecordResource> {
    constructor() {
        super("phr.backend.api.v1.HealthRecordResource", [
            { no: 1, name: "vaccination", kind: "message", oneof: "type", T: () => Vaccination },
            { no: 2, name: "past_event", kind: "message", oneof: "type", T: () => PastEvent },
            { no: 3, name: "condition", kind: "message", oneof: "type", T: () => Condition },
            { no: 4, name: "observation", kind: "message", oneof: "type", T: () => Observation },
            { no: 5, name: "procedure", kind: "message", oneof: "type", T: () => Procedure },
            { no: 6, name: "practitioner", kind: "message", oneof: "type", T: () => Practitioner },
            { no: 7, name: "patient", kind: "message", oneof: "type", T: () => Patient },
            { no: 8, name: "encounter", kind: "message", oneof: "type", T: () => RawEncounter },
            { no: 9, name: "immunization", kind: "message", oneof: "type", T: () => RawImmunization },
            { no: 10, name: "past_event_detail", kind: "message", oneof: "type", T: () => PastEventDetail },
            { no: 11, name: "procedure_detail", kind: "message", oneof: "type", T: () => ProcedureDetail }
        ]);
    }
    create(value?: PartialMessage<HealthRecordResource>): HealthRecordResource {
        const message = { type: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HealthRecordResource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HealthRecordResource): HealthRecordResource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* phr.backend.api.v1.Vaccination vaccination */ 1:
                    message.type = {
                        oneofKind: "vaccination",
                        vaccination: Vaccination.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).vaccination)
                    };
                    break;
                case /* phr.backend.api.v1.PastEvent past_event */ 2:
                    message.type = {
                        oneofKind: "pastEvent",
                        pastEvent: PastEvent.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).pastEvent)
                    };
                    break;
                case /* phr.backend.api.v1.Condition condition */ 3:
                    message.type = {
                        oneofKind: "condition",
                        condition: Condition.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).condition)
                    };
                    break;
                case /* phr.backend.api.v1.Observation observation */ 4:
                    message.type = {
                        oneofKind: "observation",
                        observation: Observation.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).observation)
                    };
                    break;
                case /* phr.backend.api.v1.Procedure procedure */ 5:
                    message.type = {
                        oneofKind: "procedure",
                        procedure: Procedure.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).procedure)
                    };
                    break;
                case /* phr.backend.api.v1.Practitioner practitioner */ 6:
                    message.type = {
                        oneofKind: "practitioner",
                        practitioner: Practitioner.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).practitioner)
                    };
                    break;
                case /* phr.backend.api.v1.Patient patient */ 7:
                    message.type = {
                        oneofKind: "patient",
                        patient: Patient.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).patient)
                    };
                    break;
                case /* phr.backend.api.v1.RawEncounter encounter */ 8:
                    message.type = {
                        oneofKind: "encounter",
                        encounter: RawEncounter.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).encounter)
                    };
                    break;
                case /* phr.backend.api.v1.RawImmunization immunization */ 9:
                    message.type = {
                        oneofKind: "immunization",
                        immunization: RawImmunization.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).immunization)
                    };
                    break;
                case /* phr.backend.api.v1.PastEventDetail past_event_detail */ 10:
                    message.type = {
                        oneofKind: "pastEventDetail",
                        pastEventDetail: PastEventDetail.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).pastEventDetail)
                    };
                    break;
                case /* phr.backend.api.v1.ProcedureDetail procedure_detail */ 11:
                    message.type = {
                        oneofKind: "procedureDetail",
                        procedureDetail: ProcedureDetail.internalBinaryRead(reader, reader.uint32(), options, (message.type as any).procedureDetail)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HealthRecordResource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* phr.backend.api.v1.Vaccination vaccination = 1; */
        if (message.type.oneofKind === "vaccination")
            Vaccination.internalBinaryWrite(message.type.vaccination, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.PastEvent past_event = 2; */
        if (message.type.oneofKind === "pastEvent")
            PastEvent.internalBinaryWrite(message.type.pastEvent, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Condition condition = 3; */
        if (message.type.oneofKind === "condition")
            Condition.internalBinaryWrite(message.type.condition, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Observation observation = 4; */
        if (message.type.oneofKind === "observation")
            Observation.internalBinaryWrite(message.type.observation, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Procedure procedure = 5; */
        if (message.type.oneofKind === "procedure")
            Procedure.internalBinaryWrite(message.type.procedure, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Practitioner practitioner = 6; */
        if (message.type.oneofKind === "practitioner")
            Practitioner.internalBinaryWrite(message.type.practitioner, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.Patient patient = 7; */
        if (message.type.oneofKind === "patient")
            Patient.internalBinaryWrite(message.type.patient, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.RawEncounter encounter = 8; */
        if (message.type.oneofKind === "encounter")
            RawEncounter.internalBinaryWrite(message.type.encounter, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.RawImmunization immunization = 9; */
        if (message.type.oneofKind === "immunization")
            RawImmunization.internalBinaryWrite(message.type.immunization, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.PastEventDetail past_event_detail = 10; */
        if (message.type.oneofKind === "pastEventDetail")
            PastEventDetail.internalBinaryWrite(message.type.pastEventDetail, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.ProcedureDetail procedure_detail = 11; */
        if (message.type.oneofKind === "procedureDetail")
            ProcedureDetail.internalBinaryWrite(message.type.procedureDetail, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.HealthRecordResource
 */
export const HealthRecordResource = new HealthRecordResource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Condition$Type extends MessageType<Condition> {
    constructor() {
        super("phr.backend.api.v1.Condition", [
            { no: 1, name: "fhir_condition", kind: "message", T: () => Condition$ }
        ]);
    }
    create(value?: PartialMessage<Condition>): Condition {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Condition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Condition): Condition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Condition fhir_condition */ 1:
                    message.fhirCondition = Condition$.internalBinaryRead(reader, reader.uint32(), options, message.fhirCondition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Condition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Condition fhir_condition = 1; */
        if (message.fhirCondition)
            Condition$.internalBinaryWrite(message.fhirCondition, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Condition
 */
export const Condition = new Condition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Observation$Type extends MessageType<Observation> {
    constructor() {
        super("phr.backend.api.v1.Observation", [
            { no: 1, name: "fhir_observation", kind: "message", T: () => Observation$ }
        ]);
    }
    create(value?: PartialMessage<Observation>): Observation {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Observation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Observation): Observation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Observation fhir_observation */ 1:
                    message.fhirObservation = Observation$.internalBinaryRead(reader, reader.uint32(), options, message.fhirObservation);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Observation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Observation fhir_observation = 1; */
        if (message.fhirObservation)
            Observation$.internalBinaryWrite(message.fhirObservation, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Observation
 */
export const Observation = new Observation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Procedure$Type extends MessageType<Procedure> {
    constructor() {
        super("phr.backend.api.v1.Procedure", [
            { no: 1, name: "fhir_procedure", kind: "message", T: () => Procedure$ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ], { "google.api.resource": { type: "verily.health/Procedure", pattern: ["procedure/{procedure_id}"], plural: "procedures", singular: "procedure" } });
    }
    create(value?: PartialMessage<Procedure>): Procedure {
        const message = { id: "", name: "", date: "", location: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Procedure>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Procedure): Procedure {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Procedure fhir_procedure */ 1:
                    message.fhirProcedure = Procedure$.internalBinaryRead(reader, reader.uint32(), options, message.fhirProcedure);
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string date */ 4:
                    message.date = reader.string();
                    break;
                case /* string location */ 5:
                    message.location = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Procedure, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Procedure fhir_procedure = 1; */
        if (message.fhirProcedure)
            Procedure$.internalBinaryWrite(message.fhirProcedure, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string date = 4; */
        if (message.date !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.date);
        /* string location = 5; */
        if (message.location !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.location);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Procedure
 */
export const Procedure = new Procedure$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcedureDetail$Type extends MessageType<ProcedureDetail> {
    constructor() {
        super("phr.backend.api.v1.ProcedureDetail", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProcedureDetail>): ProcedureDetail {
        const message = { id: "", name: "", date: "", location: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcedureDetail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcedureDetail): ProcedureDetail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string date */ 3:
                    message.date = reader.string();
                    break;
                case /* string location */ 4:
                    message.location = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcedureDetail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string date = 3; */
        if (message.date !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.date);
        /* string location = 4; */
        if (message.location !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.location);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.ProcedureDetail
 */
export const ProcedureDetail = new ProcedureDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Practitioner$Type extends MessageType<Practitioner> {
    constructor() {
        super("phr.backend.api.v1.Practitioner", [
            { no: 1, name: "fhir_practitioner", kind: "message", T: () => Practitioner$ }
        ]);
    }
    create(value?: PartialMessage<Practitioner>): Practitioner {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Practitioner>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Practitioner): Practitioner {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Practitioner fhir_practitioner */ 1:
                    message.fhirPractitioner = Practitioner$.internalBinaryRead(reader, reader.uint32(), options, message.fhirPractitioner);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Practitioner, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Practitioner fhir_practitioner = 1; */
        if (message.fhirPractitioner)
            Practitioner$.internalBinaryWrite(message.fhirPractitioner, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Practitioner
 */
export const Practitioner = new Practitioner$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Patient$Type extends MessageType<Patient> {
    constructor() {
        super("phr.backend.api.v1.Patient", [
            { no: 1, name: "fhir_patient", kind: "message", T: () => Patient$ }
        ]);
    }
    create(value?: PartialMessage<Patient>): Patient {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Patient>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Patient): Patient {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Patient fhir_patient */ 1:
                    message.fhirPatient = Patient$.internalBinaryRead(reader, reader.uint32(), options, message.fhirPatient);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Patient, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Patient fhir_patient = 1; */
        if (message.fhirPatient)
            Patient$.internalBinaryWrite(message.fhirPatient, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Patient
 */
export const Patient = new Patient$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RawEncounter$Type extends MessageType<RawEncounter> {
    constructor() {
        super("phr.backend.api.v1.RawEncounter", [
            { no: 1, name: "fhir_encounter", kind: "message", T: () => Encounter$ }
        ]);
    }
    create(value?: PartialMessage<RawEncounter>): RawEncounter {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RawEncounter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RawEncounter): RawEncounter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Encounter fhir_encounter */ 1:
                    message.fhirEncounter = Encounter$.internalBinaryRead(reader, reader.uint32(), options, message.fhirEncounter);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RawEncounter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Encounter fhir_encounter = 1; */
        if (message.fhirEncounter)
            Encounter$.internalBinaryWrite(message.fhirEncounter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.RawEncounter
 */
export const RawEncounter = new RawEncounter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RawImmunization$Type extends MessageType<RawImmunization> {
    constructor() {
        super("phr.backend.api.v1.RawImmunization", [
            { no: 1, name: "fhir_immunization", kind: "message", T: () => Immunization$ }
        ]);
    }
    create(value?: PartialMessage<RawImmunization>): RawImmunization {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RawImmunization>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RawImmunization): RawImmunization {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Immunization fhir_immunization */ 1:
                    message.fhirImmunization = Immunization$.internalBinaryRead(reader, reader.uint32(), options, message.fhirImmunization);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RawImmunization, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Immunization fhir_immunization = 1; */
        if (message.fhirImmunization)
            Immunization$.internalBinaryWrite(message.fhirImmunization, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.RawImmunization
 */
export const RawImmunization = new RawImmunization$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PastEvent$Type extends MessageType<PastEvent> {
    constructor() {
        super("phr.backend.api.v1.PastEvent", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "start_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "end_time", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "practitioner_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "practitioner_specialty", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "classification", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "organization", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "visit_notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PastEvent>): PastEvent {
        const message = { name: "", startTime: "", endTime: "", practitionerName: "", practitionerSpecialty: "", type: "", classification: "", organization: "", location: "", reason: "", visitNotes: "", title: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PastEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PastEvent): PastEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string start_time */ 2:
                    message.startTime = reader.string();
                    break;
                case /* string end_time */ 11:
                    message.endTime = reader.string();
                    break;
                case /* string practitioner_name */ 3:
                    message.practitionerName = reader.string();
                    break;
                case /* string practitioner_specialty */ 4:
                    message.practitionerSpecialty = reader.string();
                    break;
                case /* string type */ 5:
                    message.type = reader.string();
                    break;
                case /* string classification */ 6:
                    message.classification = reader.string();
                    break;
                case /* string organization */ 7:
                    message.organization = reader.string();
                    break;
                case /* string location */ 8:
                    message.location = reader.string();
                    break;
                case /* string reason */ 9:
                    message.reason = reader.string();
                    break;
                case /* string visit_notes */ 10:
                    message.visitNotes = reader.string();
                    break;
                case /* string title */ 12:
                    message.title = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PastEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string start_time = 2; */
        if (message.startTime !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.startTime);
        /* string end_time = 11; */
        if (message.endTime !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.endTime);
        /* string practitioner_name = 3; */
        if (message.practitionerName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.practitionerName);
        /* string practitioner_specialty = 4; */
        if (message.practitionerSpecialty !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.practitionerSpecialty);
        /* string type = 5; */
        if (message.type !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.type);
        /* string classification = 6; */
        if (message.classification !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.classification);
        /* string organization = 7; */
        if (message.organization !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.organization);
        /* string location = 8; */
        if (message.location !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.location);
        /* string reason = 9; */
        if (message.reason !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.reason);
        /* string visit_notes = 10; */
        if (message.visitNotes !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.visitNotes);
        /* string title = 12; */
        if (message.title !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.title);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.PastEvent
 */
export const PastEvent = new PastEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PastEventDetail$Type extends MessageType<PastEventDetail> {
    constructor() {
        super("phr.backend.api.v1.PastEventDetail", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["IDENTIFIER"] } },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PastEventDetail>): PastEventDetail {
        const message = { id: "", title: "", body: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PastEventDetail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PastEventDetail): PastEventDetail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string body */ 3:
                    message.body = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PastEventDetail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string body = 3; */
        if (message.body !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.body);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.PastEventDetail
 */
export const PastEventDetail = new PastEventDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthRecordResourcesByTypeRequest$Type extends MessageType<GetHealthRecordResourcesByTypeRequest> {
    constructor() {
        super("phr.backend.api.v1.GetHealthRecordResourcesByTypeRequest", [
            { no: 1, name: "patient_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 4, name: "resource", kind: "message", T: () => FhirResource, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 5, name: "record_type", kind: "enum", T: () => ["phr.backend.api.v1.HealthRecordResourceType", HealthRecordResourceType], options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetHealthRecordResourcesByTypeRequest>): GetHealthRecordResourcesByTypeRequest {
        const message = { patientIds: [], pageSize: 0, pageToken: "", recordType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHealthRecordResourcesByTypeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthRecordResourcesByTypeRequest): GetHealthRecordResourcesByTypeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string patient_ids */ 1:
                    message.patientIds.push(reader.string());
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                case /* phr.backend.api.v1.FhirResource resource = 4 [deprecated = true];*/ 4:
                    message.resource = FhirResource.internalBinaryRead(reader, reader.uint32(), options, message.resource);
                    break;
                case /* phr.backend.api.v1.HealthRecordResourceType record_type */ 5:
                    message.recordType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthRecordResourcesByTypeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string patient_ids = 1; */
        for (let i = 0; i < message.patientIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.patientIds[i]);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        /* phr.backend.api.v1.FhirResource resource = 4 [deprecated = true]; */
        if (message.resource)
            FhirResource.internalBinaryWrite(message.resource, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* phr.backend.api.v1.HealthRecordResourceType record_type = 5; */
        if (message.recordType !== 0)
            writer.tag(5, WireType.Varint).int32(message.recordType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeRequest
 */
export const GetHealthRecordResourcesByTypeRequest = new GetHealthRecordResourcesByTypeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthRecordResourcesByTypeResponse$Type extends MessageType<GetHealthRecordResourcesByTypeResponse> {
    constructor() {
        super("phr.backend.api.v1.GetHealthRecordResourcesByTypeResponse", [
            { no: 1, name: "resources", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HealthRecordResource }
        ]);
    }
    create(value?: PartialMessage<GetHealthRecordResourcesByTypeResponse>): GetHealthRecordResourcesByTypeResponse {
        const message = { resources: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHealthRecordResourcesByTypeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthRecordResourcesByTypeResponse): GetHealthRecordResourcesByTypeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.HealthRecordResource resources */ 1:
                    message.resources.push(HealthRecordResource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthRecordResourcesByTypeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.HealthRecordResource resources = 1; */
        for (let i = 0; i < message.resources.length; i++)
            HealthRecordResource.internalBinaryWrite(message.resources[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetHealthRecordResourcesByTypeResponse
 */
export const GetHealthRecordResourcesByTypeResponse = new GetHealthRecordResourcesByTypeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentEncountersForListOfPatientsRequest$Type extends MessageType<GetRecentEncountersForListOfPatientsRequest> {
    constructor() {
        super("phr.backend.api.v1.GetRecentEncountersForListOfPatientsRequest", [
            { no: 1, name: "patient_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "num_encounters", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetRecentEncountersForListOfPatientsRequest>): GetRecentEncountersForListOfPatientsRequest {
        const message = { patientIds: [], numEncounters: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecentEncountersForListOfPatientsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecentEncountersForListOfPatientsRequest): GetRecentEncountersForListOfPatientsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string patient_ids */ 1:
                    message.patientIds.push(reader.string());
                    break;
                case /* int32 num_encounters */ 2:
                    message.numEncounters = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecentEncountersForListOfPatientsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string patient_ids = 1; */
        for (let i = 0; i < message.patientIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.patientIds[i]);
        /* int32 num_encounters = 2; */
        if (message.numEncounters !== 0)
            writer.tag(2, WireType.Varint).int32(message.numEncounters);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetRecentEncountersForListOfPatientsRequest
 */
export const GetRecentEncountersForListOfPatientsRequest = new GetRecentEncountersForListOfPatientsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Encounters$Type extends MessageType<Encounters> {
    constructor() {
        super("phr.backend.api.v1.Encounters", [
            { no: 1, name: "encounters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Encounter }
        ]);
    }
    create(value?: PartialMessage<Encounters>): Encounters {
        const message = { encounters: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Encounters>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Encounters): Encounters {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Encounter encounters */ 1:
                    message.encounters.push(Encounter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Encounters, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Encounter encounters = 1; */
        for (let i = 0; i < message.encounters.length; i++)
            Encounter.internalBinaryWrite(message.encounters[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.Encounters
 */
export const Encounters = new Encounters$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllEncountersForListOfPatientsRequest$Type extends MessageType<GetAllEncountersForListOfPatientsRequest> {
    constructor() {
        super("phr.backend.api.v1.GetAllEncountersForListOfPatientsRequest", [
            { no: 1, name: "patient_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"] } },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"] } }
        ]);
    }
    create(value?: PartialMessage<GetAllEncountersForListOfPatientsRequest>): GetAllEncountersForListOfPatientsRequest {
        const message = { patientIds: [], pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllEncountersForListOfPatientsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllEncountersForListOfPatientsRequest): GetAllEncountersForListOfPatientsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string patient_ids */ 1:
                    message.patientIds.push(reader.string());
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllEncountersForListOfPatientsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string patient_ids = 1; */
        for (let i = 0; i < message.patientIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.patientIds[i]);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetAllEncountersForListOfPatientsRequest
 */
export const GetAllEncountersForListOfPatientsRequest = new GetAllEncountersForListOfPatientsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthRecordResourceByIDRequest$Type extends MessageType<GetHealthRecordResourceByIDRequest> {
    constructor() {
        super("phr.backend.api.v1.GetHealthRecordResourceByIDRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "record_type", kind: "enum", T: () => ["phr.backend.api.v1.HealthRecordResourceType", HealthRecordResourceType], options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetHealthRecordResourceByIDRequest>): GetHealthRecordResourceByIDRequest {
        const message = { id: "", recordType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHealthRecordResourceByIDRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthRecordResourceByIDRequest): GetHealthRecordResourceByIDRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* phr.backend.api.v1.HealthRecordResourceType record_type */ 2:
                    message.recordType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthRecordResourceByIDRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* phr.backend.api.v1.HealthRecordResourceType record_type = 2; */
        if (message.recordType !== 0)
            writer.tag(2, WireType.Varint).int32(message.recordType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetHealthRecordResourceByIDRequest
 */
export const GetHealthRecordResourceByIDRequest = new GetHealthRecordResourceByIDRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHealthRecordResourceByIDResponse$Type extends MessageType<GetHealthRecordResourceByIDResponse> {
    constructor() {
        super("phr.backend.api.v1.GetHealthRecordResourceByIDResponse", [
            { no: 1, name: "resource", kind: "message", T: () => HealthRecordResource }
        ]);
    }
    create(value?: PartialMessage<GetHealthRecordResourceByIDResponse>): GetHealthRecordResourceByIDResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHealthRecordResourceByIDResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHealthRecordResourceByIDResponse): GetHealthRecordResourceByIDResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* phr.backend.api.v1.HealthRecordResource resource */ 1:
                    message.resource = HealthRecordResource.internalBinaryRead(reader, reader.uint32(), options, message.resource);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHealthRecordResourceByIDResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* phr.backend.api.v1.HealthRecordResource resource = 1; */
        if (message.resource)
            HealthRecordResource.internalBinaryWrite(message.resource, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetHealthRecordResourceByIDResponse
 */
export const GetHealthRecordResourceByIDResponse = new GetHealthRecordResourceByIDResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPastEventDetailsFromEncounterRequest$Type extends MessageType<GetPastEventDetailsFromEncounterRequest> {
    constructor() {
        super("phr.backend.api.v1.GetPastEventDetailsFromEncounterRequest", [
            { no: 1, name: "encounter_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetPastEventDetailsFromEncounterRequest>): GetPastEventDetailsFromEncounterRequest {
        const message = { encounterId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPastEventDetailsFromEncounterRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPastEventDetailsFromEncounterRequest): GetPastEventDetailsFromEncounterRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string encounter_id */ 1:
                    message.encounterId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPastEventDetailsFromEncounterRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string encounter_id = 1; */
        if (message.encounterId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.encounterId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetPastEventDetailsFromEncounterRequest
 */
export const GetPastEventDetailsFromEncounterRequest = new GetPastEventDetailsFromEncounterRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPastEventDetailsFromEncounterResponse$Type extends MessageType<GetPastEventDetailsFromEncounterResponse> {
    constructor() {
        super("phr.backend.api.v1.GetPastEventDetailsFromEncounterResponse", [
            { no: 1, name: "past_event_details", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PastEventDetail }
        ]);
    }
    create(value?: PartialMessage<GetPastEventDetailsFromEncounterResponse>): GetPastEventDetailsFromEncounterResponse {
        const message = { pastEventDetails: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPastEventDetailsFromEncounterResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPastEventDetailsFromEncounterResponse): GetPastEventDetailsFromEncounterResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.PastEventDetail past_event_details */ 1:
                    message.pastEventDetails.push(PastEventDetail.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPastEventDetailsFromEncounterResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.PastEventDetail past_event_details = 1; */
        for (let i = 0; i < message.pastEventDetails.length; i++)
            PastEventDetail.internalBinaryWrite(message.pastEventDetails[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.backend.api.v1.GetPastEventDetailsFromEncounterResponse
 */
export const GetPastEventDetailsFromEncounterResponse = new GetPastEventDetailsFromEncounterResponse$Type();
/**
 * @generated ServiceType for protobuf service phr.backend.api.v1.PersonalHealthRecordService
 */
export const PersonalHealthRecordService = new ServiceType("phr.backend.api.v1.PersonalHealthRecordService", [
    { name: "GetRecentEncountersForListOfPatients", options: {}, I: GetRecentEncountersForListOfPatientsRequest, O: Encounters },
    { name: "GetAllEncountersForListOfPatients", options: {}, I: GetAllEncountersForListOfPatientsRequest, O: Encounters },
    { name: "GetHealthRecordResourcesByType", options: {}, I: GetHealthRecordResourcesByTypeRequest, O: GetHealthRecordResourcesByTypeResponse },
    { name: "GetHealthRecordResourceByID", options: {}, I: GetHealthRecordResourceByIDRequest, O: GetHealthRecordResourceByIDResponse },
    { name: "GetPastEventDetailsFromEncounter", options: {}, I: GetPastEventDetailsFromEncounterRequest, O: GetPastEventDetailsFromEncounterResponse }
]);
