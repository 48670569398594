import {useTheme} from '@mui/material';
import {List} from '@verily-src/react-design-system';
import {Vaccination as VaccinationType} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import {AUTOMATION_IDS} from '../../common/automationIDs';
import VaccinationDetail from '../../components/Vaccination/VaccinationDetail';

export type VaccinationsSecondaryPageContentProps = {
  vaccinations: VaccinationType[];
};

export function VaccinationSecondaryPageContent({
  vaccinations,
}: VaccinationsSecondaryPageContentProps) {
  const theme = useTheme();

  return (
    <div data-testid={AUTOMATION_IDS.SECONDARY_PAGE.VACCINATION_RECORDS}>
      <List>
        {vaccinations.map((vaccination: VaccinationType) => (
          <VaccinationDetail key={vaccination.id} vaccination={vaccination} />
        ))}
      </List>
    </div>
  );
}
