import {ThemeProvider, useTheme} from '@mui/material';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {Vaccination} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import Header from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Header/Header';
import Layout from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Layout/Layout';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import VaccinationsService from '../../services/VaccinationsService';
import {VaccinationSecondaryPageContent} from './VaccinationSecondaryPageContent';

import {api} from '@verily-src/phaf-unified-api';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';

const RELOAD_INTERVAL_MS = 5000;

export function VaccinationsSecondaryPage() {
  const {t} = useTranslation();
  const currTheme = useTheme();

  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    loadData(true);
  }, []);

  async function loadData(initialLoad?: boolean) {
    if (initialLoad) setIsLoading(true);
    const {cached, fresh} = VaccinationsService.getAllVaccinations();
    // Use the cached Profile on the initial load, if available
    if (initialLoad && cached) updateVaccinations(cached.vaccinations);
    // Update state to use actual Profile whenever available
    fresh
      .then(resp => updateVaccinations(resp.vaccinations))
      .catch(() => {
        setTimeout(() => loadData(), RELOAD_INTERVAL_MS);
        setIsLoading(false);
      });
  }

  const updateVaccinations = (vaccinations: Vaccination[]) => {
    setVaccinations(vaccinations);
    setIsLoading(false);
  };

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    currTheme ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <main>
        <Layout
          header={
            <Header
              headerText={t('vaccinations-secondary-page-header')}
              hasBackButton={true}
              backButtonDescription={t('back-button-description')}
              onBackButtonClick={() => {
                window.location.href = '/me/mobile/health-dashboard';
              }}
            />
          }
        >
          {isLoading ? (
            <PillSkeleton
              width={theme.spacing(17.5)}
              height={theme.spacing(3.5)}
            />
          ) : (
            <VaccinationSecondaryPageContent vaccinations={vaccinations} />
          )}
        </Layout>
      </main>
    </ThemeProvider>
  );
}
