/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "phr/vaccinations/bff/api/v1/vaccinations_bff.proto" (package "phr.vaccinations.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Vaccination } from "../../../../backend/api/v1/phr_service";
/**
 * Response containing the list of Vaccinations for the current participant
 *
 * @generated from protobuf message phr.vaccinations.bff.api.v1.GetAllVaccinationsRequest
 */
export interface GetAllVaccinationsRequest {
    /**
     * The maximum number of Vaccinations to return. The service may return fewer
     * than this value.
     *
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * A page token, received from a previous
     * `GetAllVaccinationsRequest` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters
     * provided to `GetAllVaccinationsRequest` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * Response containing the list of vaccinations
 *
 * @generated from protobuf message phr.vaccinations.bff.api.v1.GetAllVaccinationsResponse
 */
export interface GetAllVaccinationsResponse {
    /**
     * List of vaccinations
     *
     * @generated from protobuf field: repeated phr.backend.api.v1.Vaccination vaccinations = 1;
     */
    vaccinations: Vaccination[];
}
// @generated message type with reflection information, may provide speed optimized methods
class GetAllVaccinationsRequest$Type extends MessageType<GetAllVaccinationsRequest> {
    constructor() {
        super("phr.vaccinations.bff.api.v1.GetAllVaccinationsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["OPTIONAL"], "verily.privacy.annotations.scrub": false } },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["OPTIONAL"], "verily.privacy.annotations.scrub": false } }
        ]);
    }
    create(value?: PartialMessage<GetAllVaccinationsRequest>): GetAllVaccinationsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllVaccinationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllVaccinationsRequest): GetAllVaccinationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllVaccinationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.vaccinations.bff.api.v1.GetAllVaccinationsRequest
 */
export const GetAllVaccinationsRequest = new GetAllVaccinationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllVaccinationsResponse$Type extends MessageType<GetAllVaccinationsResponse> {
    constructor() {
        super("phr.vaccinations.bff.api.v1.GetAllVaccinationsResponse", [
            { no: 1, name: "vaccinations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Vaccination }
        ]);
    }
    create(value?: PartialMessage<GetAllVaccinationsResponse>): GetAllVaccinationsResponse {
        const message = { vaccinations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllVaccinationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllVaccinationsResponse): GetAllVaccinationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated phr.backend.api.v1.Vaccination vaccinations */ 1:
                    message.vaccinations.push(Vaccination.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllVaccinationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated phr.backend.api.v1.Vaccination vaccinations = 1; */
        for (let i = 0; i < message.vaccinations.length; i++)
            Vaccination.internalBinaryWrite(message.vaccinations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message phr.vaccinations.bff.api.v1.GetAllVaccinationsResponse
 */
export const GetAllVaccinationsResponse = new GetAllVaccinationsResponse$Type();
/**
 * @generated ServiceType for protobuf service phr.vaccinations.bff.api.v1.VaccinationsBFFService
 */
export const VaccinationsBFFService = new ServiceType("phr.vaccinations.bff.api.v1.VaccinationsBFFService", [
    { name: "GetAllVaccinations", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: GetAllVaccinationsRequest, O: GetAllVaccinationsResponse }
]);
