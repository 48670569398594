import {ListItem} from '@verily-src/react-design-system';
import {Vaccination} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';

export type VaccinationDetailProps = {
  vaccination: Vaccination;
};

function extractFormattedDateFromISO8601String(date: string) {
  // ISO 8601 date format:
  // YYYY-MM-DDThh:mm:ss+zz:zz
  const datePortion = date.split('T')[0];
  const [year, month, day] = datePortion.split('-');
  return `${new Date(
    Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
  ).toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  })}`;
}

export default function VaccinationDetail({
  vaccination,
}: VaccinationDetailProps) {
  const {date, location, name} = vaccination;

  const formattedDate = date
    ? extractFormattedDateFromISO8601String(date)
    : null;

  const secondaryTextParts: string[] = [];
  if (formattedDate) {
    secondaryTextParts.push(formattedDate);
  }
  if (location) {
    secondaryTextParts.push(location);
  }
  const secondaryText = secondaryTextParts.join(' • ');

  return <ListItem primaryText={name} secondaryText={secondaryText} isStatic />;
}
