import {Typography, useTheme} from '@mui/material';
import {Vaccination} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import PillSkeleton from '@verily-src/verily1-verily-me-web-common-typescript/src/components/Skeleton/PillSkeleton';
import {useTranslation} from 'react-i18next';
import vaccinesLogo from '../../common/assets/vaccinesLogo.svg';
import {AUTOMATION_IDS} from '../../common/automationIDs';
import styles from './VaccinationsPrimaryContent.module.css';
type VaccinationsPrimaryContentProps = {
  isLoading: boolean;
  vaccinations: Vaccination[];
};

export function VaccinationsPrimaryContent(
  props: VaccinationsPrimaryContentProps
) {
  const {t} = useTranslation();
  const theme = useTheme();
  const {isLoading, vaccinations} = props;

  return (
    <>
      {isLoading ? (
        <div style={{marginBottom: '20px'}} role="status" aria-busy={true}>
          <PillSkeleton
            width={theme.spacing(17.5)}
            height={theme.spacing(3.5)}
          />
        </div>
      ) : (
        <>
          <div className={styles.vaccinationCardHeader}>
            <img src={vaccinesLogo} alt={t('vaccines-logo')} />
            <Typography component={'h2'} variant="h6">
              {t('vaccinations-primary-page-card-header')}
            </Typography>
          </div>
          <Typography
            data-testid={`${AUTOMATION_IDS.PRIMARY_PAGE.VACCINATION_RECORDS}`}
          >
            {vaccinations.length} Records
          </Typography>
        </>
      )}
    </>
  );
}
