import {Card, CardActionArea, CardContent, ThemeProvider} from '@mui/material';
import {api} from '@verily-src/phaf-unified-api';
import {
  ConsumerLightTheme,
  verilyThemeLookup,
} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api/src/verily-verily-me-api';
import {useEffect, useState} from 'react';

import {Vaccination} from '@verily-src/verily1-protos/phr/backend/api/v1/phr_service';
import {secondaryPageRoute} from 'components/Root/Root';
import {VaccinationsPrimaryContent} from 'pages/VaccinationsPrimaryContent/VaccinationsPrimaryContent';
import {navigateToUrl} from 'single-spa';
import VaccinationsService from '../../services/VaccinationsService';
import styles from './VaccinationsPrimaryCard.module.css';

const RELOAD_INTERVAL_MS = 5000;

export function VaccinationsPrimaryCard() {
  const [vaccinations, setVaccinations] = useState<Vaccination[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData(true);
  }, []);

  async function loadData(initialLoad?: boolean) {
    if (initialLoad) setIsLoading(true);
    const {cached, fresh} = VaccinationsService.getAllVaccinations();
    // Use the cached Profile on the initial load, if available
    if (initialLoad && cached) updateVaccinations(cached.vaccinations);
    // Update state to use actual Profile whenever available
    fresh
      .then(resp => updateVaccinations(resp.vaccinations))
      .catch(() => {
        setTimeout(() => loadData(), RELOAD_INTERVAL_MS);
        setIsLoading(false);
      });
  }

  const updateVaccinations = (vaccinations: Vaccination[]) => {
    setVaccinations(vaccinations);
    setIsLoading(false);
  };

  const onCardClick = async () => {
    if (vaccinations.length === 0 || isLoading) return;
    const newUrl = new URL(window.location.href);
    newUrl.pathname = secondaryPageRoute;

    navigateToUrl(newUrl.toString());
  };

  const theme =
    verilyThemeLookup((api as typeof VerilyMeApi).theme?.theme) ??
    ConsumerLightTheme;
  const locale =
    (api as typeof VerilyMeApi).theme?.locale ?? navigator.language;

  return (
    <ThemeProvider theme={{...theme, locale}}>
      <Card>
        <CardActionArea
          className={`${
            isLoading || vaccinations.length === 0
              ? `${styles.emptyVaccinationsContainer}`
              : ''
          }`}
          onClick={onCardClick}
        >
          <CardContent>
            <VaccinationsPrimaryContent
              isLoading={isLoading}
              vaccinations={vaccinations}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </ThemeProvider>
  );
}
