/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "phr/vaccinations/bff/api/v1/vaccinations_bff.proto" (package "phr.vaccinations.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { VaccinationsBFFService } from "./vaccinations_bff";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetAllVaccinationsResponse } from "./vaccinations_bff";
import type { GetAllVaccinationsRequest } from "./vaccinations_bff";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * APIs for PHR Vaccinations MFE to display historical patient health record immunizations data
 *
 * @generated from protobuf service phr.vaccinations.bff.api.v1.VaccinationsBFFService
 */
export interface IVaccinationsBFFServiceClient {
    /**
     * Get all of the patient’s vaccinations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Immunization - BFF communicates with the PHR Backend to read Immunization resources, which is the FHIR resource that stores the Vaccinations data
     *
     * @generated from protobuf rpc: GetAllVaccinations(phr.vaccinations.bff.api.v1.GetAllVaccinationsRequest) returns (phr.vaccinations.bff.api.v1.GetAllVaccinationsResponse);
     */
    getAllVaccinations(input: GetAllVaccinationsRequest, options?: RpcOptions): UnaryCall<GetAllVaccinationsRequest, GetAllVaccinationsResponse>;
}
/**
 * APIs for PHR Vaccinations MFE to display historical patient health record immunizations data
 *
 * @generated from protobuf service phr.vaccinations.bff.api.v1.VaccinationsBFFService
 */
export class VaccinationsBFFServiceClient implements IVaccinationsBFFServiceClient, ServiceInfo {
    typeName = VaccinationsBFFService.typeName;
    methods = VaccinationsBFFService.methods;
    options = VaccinationsBFFService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Get all of the patient’s vaccinations
     * FHIR Interactions:
     * - Patient - BFF queries the Participant Backend for a specific ParticipantRecord by pulling the logged in user’s PersonID (extracted from CIAM).
     * - Immunization - BFF communicates with the PHR Backend to read Immunization resources, which is the FHIR resource that stores the Vaccinations data
     *
     * @generated from protobuf rpc: GetAllVaccinations(phr.vaccinations.bff.api.v1.GetAllVaccinationsRequest) returns (phr.vaccinations.bff.api.v1.GetAllVaccinationsResponse);
     */
    getAllVaccinations(input: GetAllVaccinationsRequest, options?: RpcOptions): UnaryCall<GetAllVaccinationsRequest, GetAllVaccinationsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAllVaccinationsRequest, GetAllVaccinationsResponse>("unary", this._transport, method, opt, input);
    }
}
