// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vaccinationCardHeader___S2Gd{display:flex;height:36px;width:36px}`, "",{"version":3,"sources":["webpack://./src/pages/VaccinationsPrimaryContent/VaccinationsPrimaryContent.module.css"],"names":[],"mappings":"AAAA,8BACE,YAAa,CACb,WAAY,CACZ,UACF","sourcesContent":[".vaccinationCardHeader {\n  display: flex;\n  height: 36px;\n  width: 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vaccinationCardHeader": `vaccinationCardHeader___S2Gd`
};
export default ___CSS_LOADER_EXPORT___;
