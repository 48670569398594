// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emptyVaccinationsContainer__QLEnZ:hover{cursor:default}`, "",{"version":3,"sources":["webpack://./src/pages/VaccinationsPrimaryCard/VaccinationsPrimaryCard.module.css"],"names":[],"mappings":"AAAA,yCACE,cACF","sourcesContent":[".emptyVaccinationsContainer:hover {\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyVaccinationsContainer": `emptyVaccinationsContainer__QLEnZ`
};
export default ___CSS_LOADER_EXPORT___;
