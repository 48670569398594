import {VaccinationsPrimaryCard} from 'pages/VaccinationsPrimaryCard/VaccinationsPrimaryCard';
import {VaccinationsSecondaryPage} from 'pages/VaccinationsSecondaryPage/VaccinationsSecondaryPage';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
export const primaryPageRoute = '/me/mobile/health-dashboard';
export const secondaryPageRoute = '/me/mobile/health-dashboard/vaccinations';
export default function Root() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={primaryPageRoute}
          element={<VaccinationsPrimaryCard />}
        ></Route>
        <Route
          path={secondaryPageRoute}
          element={<VaccinationsSecondaryPage />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}
